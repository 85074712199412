import { Injectable } from '@angular/core';
import { AccountInfo, AuthenticationResult, Configuration, EndSessionRequest, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, catchError, from, interval, of, switchMap, throwError } from 'rxjs';
import { USERS_ENDPOINT } from '../../../shared/constants';
import { UserBasicInfo } from '../models/UserBasicInfo';
import { ApiService } from '../../../shared/services';

@Injectable()
export class AuthService {
  private pca: PublicClientApplication;
  //TODO: Maybe another type will be used in the future, so this will most probably be changed
  private userSubject: BehaviorSubject<UserBasicInfo | null>;
  private usersApiBaseUrl = USERS_ENDPOINT;
  private account: AccountInfo | null = null;
  private isInitialized = false;
  private initializationPromise: Promise<void> | null = null;

  constructor(private router: Router, private apiService: ApiService) {
    const msalConfig: Configuration = {
      auth: {
        clientId: environment.msalConfig.auth.clientId,
        authority: environment.msalConfig.auth.authority,
        redirectUri: environment.msalConfig.auth.redirectUri,
      },
      cache: {
        cacheLocation: environment.msalConfig.cache.cacheLocation,
      },
    };
    this.pca = new PublicClientApplication(msalConfig);

    this.userSubject = new BehaviorSubject<UserBasicInfo | null>(JSON.parse(localStorage.getItem('user')!));
  }

  async initialize(): Promise<void> {
    if (this.isInitialized) {
      return;
    }
    if (!this.initializationPromise) {
      this.initializationPromise = this.pca.initialize();
      await this.initializationPromise;
      this.isInitialized = true;

      // After initialization, set the account
      const accounts = this.pca.getAllAccounts();
      if (accounts.length > 0) {
        this.account = accounts[0];
      }

      // Set up a token renewal mechanism
      this._setupTokenRenewal();
    } else {
      await this.initializationPromise;
    }
  }

  get authenticatedUser(): UserBasicInfo | null {
    return this.userSubject.value;
  }

  login(): Observable<UserBasicInfo> {
    return from(this.initialize()).pipe(
      switchMap(() => {
        return from(this.pca.loginPopup({ scopes: environment.msalConfig.apiConfig.scopes })).pipe(
          switchMap((result: AuthenticationResult) => {

            this.account = result.account;
            // localStorage.setItem('token', result.accessToken);

            return this._getAuthenticatedUserBasicInfo().pipe(
              switchMap((user: UserBasicInfo) => {
                this.userSubject.next(user);
                localStorage.setItem('user', JSON.stringify(user));
                this.router.navigate(['/dashboard']);
                return of(user);
              }),
            );
          }),
        );
      }));
  }

  logout(): void {
    if (!this.account) {
      // If there is no account, simply navigate to the login page
      this.router.navigate(['/login']);
      return;
    }

    const logoutRedirectUrl = `${environment.msalConfig.auth.redirectUri}/login`;
    console.log(logoutRedirectUrl);

    const logoutRequest: EndSessionRequest = {
      account: this.account,
      postLogoutRedirectUri: logoutRedirectUrl,
    };

    // Clear local user data
    this.userSubject.next(null);
    localStorage.removeItem('user');

    this.pca.logoutPopup(logoutRequest).catch((error) => {
      console.error('Logout failed:', error);
      this.router.navigate(['/login']);
    });
  }

  private _getAuthenticatedUserBasicInfo(): Observable<UserBasicInfo> {
    return this.apiService.get(this.usersApiBaseUrl);
  }

  getAccessToken(): Observable<string> {
    return from(this.initialize()).pipe(
      switchMap(() => {
        const request = {
          scopes: environment.msalConfig.apiConfig.scopes,
          account: this.account!,
        };

        return from(this.pca.acquireTokenSilent(request)).pipe(
          switchMap((result: AuthenticationResult) => {
            return of(result.accessToken);
          }),
          // Handle token acquisition errors
          catchError((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              // Fallback to interactive method if silent acquisition fails
              return from(this.pca.acquireTokenPopup(request)).pipe(
                switchMap((result: AuthenticationResult) => {
                  return of(result.accessToken);
                }),
              );
            } else {
              // Handle other errors
              return throwError(error);
            }
          }),
        );
      })
    );
  }

  private _setupTokenRenewal() {
    // Check token expiration every 5 minutes
    interval(10 * 60 * 1000).subscribe(() => {
      if (this.account) {
        this.getAccessToken().subscribe({
          next: () => {
            // Token renewed successfully
          },
          error: (error) => {
            // Handle errors (e.g., redirect to login if unable to renew token)
            this.logout();
          },
        });
      }
    });
  }
}
